import APP_CONST from '@/constants/AppConst';
import analyticsStore from '@/store/modules/analyticsStore';
import { Vue, Component } from 'vue-property-decorator';
import FamilyEnagementCard from '../../commonComponents/familyEnagementCard/FamilyEnagementCard.vue';
import APP_UTILITIES from '@/utilities/commonFunctions';
import BouncingPreloaderComponent from '@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue';


@Component({
  components: {
    'family-enagement-card' : FamilyEnagementCard,
    'bouncing-preloader': BouncingPreloaderComponent,
  }
})


export default class FamilyTabEngagementAnalyticsComponent extends Vue {

    public bxForFamilies: string = APP_CONST.BX_FOR_FAMILIES;
    public FamilyPortal: string = APP_CONST.FAMILY_PORTAL;
    public totalActiveAccounts : string =  APP_CONST.TOTAL_ACTIVE_ACCOUNTS;
    public activeMobileUsers : string = APP_CONST.ACTIVE_MOBILE_USERS;
    public totalDirectMessages : string = APP_CONST.TOTAL_DIRECT_MESSAGES;
    public likedPosts : string = APP_CONST.TOTAL_LIKED_POSTS;
    public likedPostsImage :string = APP_CONST.LIKED_POSTS;
    public activeAccountsImage: string = APP_CONST.ACTIVE_ACCOUNTS_IMAGE;
    public message_image:string = APP_CONST.MESSAGES_IMAGE;
    public activeMobileUsersImage:string = APP_CONST.ACTIVE_MOBILE_USERS_IMAGE;
    public noData :string = APP_CONST.BLANK_PHONE_NUMBER;
    public isLoaderVisible: boolean = false;

    get familyEnagementFamilyData(){
      return analyticsStore.familyEnagementFamilyDataObj;
    }

    beforeMount(){
      this.isLoaderVisible = true;
      const accountId:number = Number(APP_UTILITIES.getCookie('accountId'));
      analyticsStore.mutateFamilyEngagementTab('family');
      analyticsStore.fetchFamilyEnagementFamilyData(accountId).then(()=>{
        this.isLoaderVisible = false;
      });
    }
}